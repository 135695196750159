.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

img {
  width: 100%; }

.animation {
  display: grid; }

.works {
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .works-desc {
    margin-top: 25px; }
    .works-desc__sub-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 45px; }
      .works-desc__sub-title h2 {
        margin-bottom: 50px;
        color: #000000;
        font-size: 36px;
        font-weight: 700;
        line-height: 42.04px;
        border-top: 2px solid #27b2f3;
        padding-top: 20px; }
    .works-desc__title {
      max-width: 743px;
      font-size: 76px;
      font-weight: 700;
      line-height: 80px;
      font-family: "Montserrat";
      color: #000000;
      margin-bottom: 42px; }
    .works-desc__text {
      font-size: 18px;
      max-width: 780px; }
      .works-desc__text p:first-child {
        margin-bottom: 30px; }
  .works-carts .cart:nth-child(even) .cart__img {
    order: 2; }
  .works-carts .cart:nth-child(even) .cart__description {
    margin-left: 0px; }
  .works-botton {
    margin-bottom: 80px; }

@media (max-width: 1000px) {
  .works-carts .cart:nth-child(even) .cart__img {
    order: 0; } }

@media (max-width: 991px) {
  .works-desc__title {
    font-size: 60px;
    line-height: 70px; } }

@media (max-width: 767px) {
  .works-desc {
    margin-top: 80px;
    margin-bottom: 60px; }
    .works-desc__sub-title {
      justify-content: center;
      margin-bottom: 60px; }
      .works-desc__sub-title h2 {
        font-size: 30px;
        display: block;
        text-align: center;
        border-width: 6px;
        padding-top: 8px; }
    .works-desc__title {
      text-align: center; }
    .works-desc__text {
      text-align: center; } }

@media (max-width: 540px) {
  .works-desc__title {
    margin: 0 0 24px;
    font-size: 30px;
    line-height: 42px;
    font-weight: 600; }
  .works-desc__sub-title {
    margin-bottom: 40px; }
    .works-desc__sub-title h2 {
      font-size: 24px; }
  .works-desc__text {
    opacity: 1; } }
