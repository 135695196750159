.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.footer-form {
  font: 15px Montserrat, arial, sans-serif; }
  .footer-form .title-footer {
    margin-top: 57px !important;
    margin-bottom: 0px !important;
    color: #212121;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    background-color: transparent; }
  .footer-form .payform .input {
    position: relative; }
  .footer-form .request-messages {
    margin-top: 40px;
    margin-bottom: 120px; }
    .footer-form .request-messages span {
      font-size: 16px; }
    .footer-form .request-messages .valid {
      display: block;
      color: #27b2f3;
      position: relative;
      padding-left: 23px; }
      .footer-form .request-messages .valid:before {
        position: absolute;
        display: block;
        content: "";
        width: 15px;
        height: 12px;
        background: url("../../../../img/arrow-submit.png") no-repeat;
        left: 0;
        top: 5px; }
    .footer-form .request-messages .warning {
      color: #ffac0a; }
    .footer-form .request-messages .error {
      color: #ff3a24; }
  .footer-form .label-hidden {
    left: 0;
    position: absolute;
    color: transparent; }
  .footer-form .valid-message {
    position: absolute;
    bottom: -15px;
    font-size: 11px;
    color: red; }
  .footer-form .text-input {
    width: 100%;
    border: none;
    padding: 26px 0 13px;
    background-color: transparent;
    transition: 400ms all;
    border-bottom: 1px solid #242d3c; }

@-webkit-keyframes autofill {
  to {
    color: #000;
    background: transparent; } }
  .footer-form .text-input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; }
  .footer-form .text-input:focus {
    border-bottom: 1px solid #27b2f3;
    transition: 400ms all; }
  .footer-form .text-input [type="text"] {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px; }
  .footer-form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #aeaeae; }
  .footer-form ::-moz-placeholder {
    /* Firefox 19+ */
    color: #aeaeae; }
  .footer-form :-ms-input-placeholder {
    /* IE 10+ */
    color: #aeaeae; }
  .footer-form :-moz-placeholder {
    /* Firefox 18- */
    color: #aeaeae; }
  .footer-form .text-input.error {
    border-bottom: 1px solid red; }
  .footer-form .text-input.error::-webkit-input-placeholder {
    color: red; }
  .footer-form .submit {
    margin-top: 40px;
    margin-bottom: 64px;
    padding: 0;
    border-radius: 3px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 50px;
    background-color: #242d3c; }
  .footer-form .submit:hover {
    cursor: pointer; }
  .footer-form .submit.loading {
    background: #0093d7;
    color: #ffffff;
    background-size: contain;
    background-position: right; }
  .footer-form .submit-text {
    position: relative;
    font-weight: 600;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px; }
  .footer-form .loading-dots {
    position: absolute;
    bottom: 0;
    right: -17px;
    padding-left: 5px;
    width: 20px;
    text-align: left; }
  .footer-form .afterfooter {
    margin-bottom: 37px;
    color: #6f6f6f;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; }
  @media (max-width: 767.98px) {
    .footer-form .afterfooter {
      display: none; } }
  @media (max-width: 400px) {
    .footer-form .payform {
      padding-right: 8px;
      padding-left: 8px; }
    .footer-form .submit {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 8px; }
    .footer-form #prices-id {
      margin: 0;
      padding: 0; }
    .footer-form .title-footer {
      margin-left: 36px;
      margin-right: 28px; }
    .footer-form .payform {
      margin-top: 29px; }
      .footer-form .payform .input {
        margin-left: 28px;
        margin-right: 28px; }
      .footer-form .payform .request-messages {
        padding-left: 28px; } }
