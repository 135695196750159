.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.navigator {
  background-color: #f2f3f4; }

.navi {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 60px 0; }
  .navi .navi__flag-wrap {
    -webkit-appearance: none;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50% 50%;
    outline: none;
    border: none;
    background-color: #27b2f3; }
  .navi .langs-side-menu {
    display: flex;
    justify-content: center;
    bottom: 10vh;
    z-index: 99999999;
    text-transform: uppercase;
    position: fixed;
    width: 80vw;
    right: -80%;
    transition: 0.8s;
    font-size: 24px; }
    .navi .langs-side-menu .item {
      margin-right: 5px;
      cursor: pointer; }
      .navi .langs-side-menu .item.active {
        color: #27b2f3; }
  .navi .select_lang {
    position: relative; }
    .navi .select_lang .label,
    .navi .select_lang .item {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase; }
    .navi .select_lang .label {
      position: absolute;
      top: 3px;
      right: 0;
      height: 35px;
      width: 35px;
      color: #000000;
      border-radius: 50% 50%;
      background-color: #ffffff;
      border: 1px solid #e7e7ef;
      z-index: 2; }
    .navi .select_lang .label:hover,
    .navi .select_lang .item:hover {
      cursor: pointer; }
    .navi .select_lang .item:hover {
      background-color: #8fc6f3; }
    .navi .select_lang .langs {
      position: absolute;
      top: 14px;
      right: 0;
      z-index: 1;
      width: 35px;
      background-color: #ffffff;
      border-radius: 0 0 20px 20px;
      padding-top: 20px; }
      .navi .select_lang .langs .item {
        height: 30px; }
      .navi .select_lang .langs .item:last-child {
        border-radius: 0 0 20px 20px; }
      .navi .select_lang .langs .act {
        display: none !important; }
  .navi .select {
    height: 40px;
    width: 40px;
    border-radius: 50% 50%; }
  .navi select {
    width: 40px;
    color: white;
    outline: none;
    box-shadow: none;
    text-align-last: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-appearance: none; }
  .navi select:hover {
    cursor: pointer; }
  .navi option {
    width: 40px;
    color: #27b2f3;
    text-align-last: center;
    text-align: center;
    background-color: white; }
  .navi__logo {
    display: block;
    height: 44px; }
    .navi__logo-wrap {
      width: 122px;
      height: 44px; }
  .navi__flag {
    width: 40px;
    height: 25px;
    background-image: url(../../../../img/rus.png);
    border-top: 1px solid #c2c2c2; }
    .navi__flag-wrap {
      display: flex;
      align-items: center; }
      .navi__flag-wrap :not(:last-child) {
        margin-right: 20px; }
    .navi__flag-uk {
      background-image: url(../../../../img/uk.png);
      border-top: 1px solid transparent; }
  .navi__navigation {
    padding: 0 40px;
    width: 769px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e7e8ec;
    border-radius: 20px; }
    .navi__navigation a:link,
    .navi__navigation a:visited {
      display: block;
      height: 100%;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      padding: 3px; }
  .navi__link {
    font-family: Montserrat, arial, sans-serif;
    cursor: pointer;
    display: block;
    height: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    padding: 3px;
    color: #000000; }
    .navi__link:hover {
      font-weight: 600;
      font-size: 14px; }
  .navi__item-text {
    transition: all 0.2s; }
    .navi__item-text {
      position: relative; }
    .navi__item-text:hover {
      color: #27b2f3; }
    .navi__item-text:hover:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -13px;
      width: 100%;
      height: 1px;
      background-color: #27b2f3; }

.navi:before {
  width: 0%;
  height: 100vh;
  transition: 0.8s;
  display: block;
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f2f3f4;
  z-index: 999; }

.navi:after {
  width: 0%;
  transition: 0.8s;
  display: block;
  content: "";
  height: 100vh;
  background: rgba(11, 11, 11, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: 0.8s; }

.hamburger {
  display: none;
  z-index: 999; }

.active > span {
  color: #27b2f3; }
  .active > span:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -13px;
    width: 100%;
    height: 1px;
    background-color: #27b2f3; }

@media (max-width: 991px) {
  .navi__navigation {
    width: 70%; }
    .navi__navigation a:link,
    .navi__navigation a:visited {
      font-size: 13px; } }

@media (max-width: 767px) {
  .navigator .container {
    max-width: 100% !important;
    padding-left: 0px; }
  .navi {
    padding: 10px 0px 0px 0px;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    z-index: 10000;
    width: 100%;
    background: #f2f3f4; }
    .navi .select_lang {
      display: none; }
    .navi__logo {
      padding-left: 16px;
      padding-top: 7px; }
      .navi__logo img {
        width: 62px;
        height: 22px; }
    .navi__navigation {
      position: fixed;
      top: 15vh;
      right: -80%;
      transition: right 0.8s;
      border: none;
      flex-direction: column;
      width: 80%;
      z-index: 99999; }
      .navi__navigation li {
        margin-bottom: 7vh;
        text-align: center; }
      .navi__navigation a:link,
      .navi__navigation a:visited {
        font-size: 24px;
        line-height: 42px; }
    .navi__link {
      font-size: 24px;
      line-height: 42px; }
      .navi__link:hover {
        font-size: 24px; }
  .navi.visible .langs-side-menu {
    right: 0%;
    transition: 0.8s; }
  .navi.visible .navi__navigation {
    right: 0;
    width: 80%;
    padding: 0;
    transition: right 0.8s;
    z-index: 9999999; }
  .navi.visible:before {
    width: 80%;
    transition: 0.8s; }
  .navi.visible:after {
    width: 20%;
    transition: 0.8s; }
  .hamburger {
    display: block;
    position: fixed;
    z-index: 9999;
    right: 9px;
    top: 4px; } }

@media (max-width: 540px) {
  .navi {
    /*  padding: 17px 10px 0px;
    padding-left: 25px; */ }
    .navi__navigation {
      padding: 0; }
  .hamburger {
    top: -7px;
    position: relative; } }
