@import "../../components/App/variables.scss";
img {
    width: 100%;
}
.title {
    font-size: 50px;
    line-height: 60px;
    color: $color-black;
    font-family: "Montserrat";
}
.project {
    display: flex;
    flex-direction: column;
    &-title {
        font-size: 76px;
        font-weight: 700;
        line-height: 80px;
        font-family: "Montserrat";
        color: $color-black;
        &:last-child,
        &:first-child {
            margin-top: 65px;
            margin-bottom: 32px;
        }
    }
    &-aim {
        &__image {
            max-width: 930px;
        }
    }

    &-developing {
        margin-bottom: 50px;

        &__image {
            max-width: 720px;
            margin-bottom: 45px;
        }
    }
    &-design {
        &__image {
            max-width: 930px;
        }
    }
    &-experience {
        &__images {
            display: flex;
        }
        &__image {
            &:first-child {
                margin-right: 25px;
            }
        }
    }

    &-similar {
        &__title {
            font-size: 24px;
            font-weight: 700;
            line-height: 42px;
            &:last-child {
                margin-bottom: 48px;
            }
        }
        &__carts {
            display: flex;
            padding: 0 35px;
            margin-bottom: 65px;
        }
    }
}
/* .cart {
    &:not(:last-child) {
        margin-right: 30px;
    }
} */
@media (max-width: 991px) {
    .project {
        &-title {
            font-size: 50px;
            line-height: 70px;
        }
        &-similar {
            &__carts {
                padding: 0 16px;
            }
        }
    }
    .cart {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}
@media (max-width: 767px) {
    .text {
        font-size: 18px;
    }
    .project {
        .title {
            font-size: 30px;
        }
        &-title {
            &:last-child,
            &:first-child {
                margin-top: 40px;
            }
        }
        &-similar {
            &__carts {
                flex-direction: column;
                padding: 0 16px;
            }
        }
        &-developing {
            margin-bottom: 40px;
        }
    }
    .cart {
        &:not(:last-child) {
            margin-right: 0px;
        }
        margin-bottom: 32px;
    }
}
@media (max-width: 540px) {
    .project {
        .text {
            opacity: 1;
        }
        .title {
            font-size: 28px;
        }
        &-title {
            font-size: 30px;
            line-height: 42px;
            &:last-child,
            &:first-child {
                margin-top: 25px;
            }
        }
        &-experience {
            &__image {
                &:last-child {
                    display: none;
                }
            }
        }
    }
}
