.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.header {
  font: 15px Montserrat, arial, sans-serif;
  padding: 0px 0 45px;
  height: 330px;
  background-color: #f2f3f4;
  position: relative;
  /************************   Arrows   ***************************/
  /***********************************************************************************/ }
  .header .big-logo {
    display: none; }
  .header-desc {
    display: flex;
    flex-direction: column;
    width: 475px; }
    .header-desc :not(:last-child) {
      margin-bottom: 27px; }
    .header-desc__item {
      display: flex;
      position: relative;
      color: #000000;
      font-size: 46px;
      font-weight: 700;
      line-height: 46px;
      margin: 10px 5px 10px 0px; }
  .header-laptop {
    position: absolute;
    top: 0px;
    left: 50%;
    width: 616px;
    height: 372px;
    background-image: url(../../../../img/laptop1.webp);
    background-size: cover; }
    .header-laptop__page {
      position: absolute;
      width: 459px;
      height: 287px;
      top: 21px;
      left: 80px;
      z-index: 2; }
      .header-laptop__page .laptop-inside {
        position: absolute;
        height: 100%;
        background-position: 50% 0%;
        background-size: cover;
        transition: all 0.3s; }
      .header-laptop__page .slick-active .laptop-inside {
        animation-duration: 10s;
        animation-delay: 2s;
        animation-name: scroll;
        animation-iteration-count: infinite; }
      .header-laptop__page .slick-active .note-img-2.laptop-inside {
        animation-duration: 6s;
        animation-delay: 2s;
        animation-name: scroll1;
        animation-iteration-count: infinite; }
      .header-laptop__page-wrap {
        position: relative;
        width: 100%;
        height: 100%; }
    .header-laptop__page-wrap {
      margin-bottom: 20px; }
    .header-laptop__link {
      text-align: center;
      background-color: #242d3c;
      max-width: 344px;
      padding: 10px 0;
      margin: 0 auto;
      display: block;
      color: #ffffff;
      font-size: 20px;
      font-weight: 550;
      line-height: 36px; }
      .header-laptop__link:link, .header-laptop__link:visited, .header-laptop__link:hover {
        font-size: 20px;
        color: #ffffff; }
  @media screen and (min-width: 0\0) {
    .header-laptop {
      background-image: url(../../../../img/laptop1.png); } }
  .header .note-img-1 {
    background-image: url(../../../../img/sites/load.webp); }
  .header .note-img-2 {
    background-image: url(../../../../img/sites/car.webp); }
  .header .note-img-3 {
    background-image: url(../../../../img/sites/neitron.webp); }
  .header .note-img-4 {
    background-image: url(../../../../img/sites/trade.webp); }
  .header .note-img-5 {
    background-image: url(../../../../img/sites/victoria.webp); }
  @media screen and (min-width: 0\0) {
    .header .note-img-1 {
      background-image: url(../../../../img/sites/load.png); }
    .header .note-img-2 {
      background-image: url(../../../../img/sites/car.png); }
    .header .note-img-3 {
      background-image: url(../../../../img/sites/neitron.png); }
    .header .note-img-4 {
      background-image: url(../../../../img/sites/trade.png); }
    .header .note-img-5 {
      background-image: url(../../../../img/sites/victoria.png); } }
  .header .slick-list,
  .header .slick-slider,
  .header .slick-track {
    height: 100%; }
  .header .slick-initialized .slick-slide {
    position: relative; }
  .header .slick-prev,
  .header .slick-next {
    height: 0px;
    width: 0px;
    z-index: 999; }
  .header .slick-prev:before,
  .header .slick-next:before {
    content: "";
    background: url("../../../../img/arrow.png") no-repeat;
    position: absolute;
    height: 28px;
    width: 14px; }
  .header .slick-prev:before {
    left: -35px; }
  .header .slick-next:before {
    transform: rotate(180deg);
    right: -35px; }
  .header .slick-dots {
    height: 20px;
    width: 120px;
    display: flex !important;
    align-items: center;
    left: 50%;
    margin-left: -60px;
    bottom: 10px;
    border-radius: 20px 20px;
    background-color: rgba(255, 255, 255, 0.6); }
    .header .slick-dots li button:before {
      top: 5px;
      left: 5px;
      height: 10px;
      width: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50% 50%;
      border: 1px solid #b9b9b9;
      color: rgba(255, 255, 255, 0.6); }
    .header .slick-dots li.slick-active button:before {
      color: black;
      background-color: black; }

/************************   Glitch   ***************************/
@keyframes noise-anim {
  0% {
    clip: rect(88px, 9999px, 65px, 0); }
  10% {
    clip: rect(3px, 9999px, 29px, 0); }
  20% {
    clip: rect(83px, 9999px, 29px, 0); }
  30% {
    clip: rect(41px, 9999px, 48px, 0); }
  40% {
    clip: rect(45px, 9999px, 73px, 0); }
  50% {
    clip: rect(100px, 9999px, 22px, 0); }
  60% {
    clip: rect(1px, 9999px, 25px, 0); }
  70% {
    clip: rect(29px, 9999px, 54px, 0); }
  80% {
    clip: rect(69px, 9999px, 80px, 0); }
  90% {
    clip: rect(35px, 9999px, 71px, 0); }
  100% {
    clip: rect(61px, 9999px, 54px, 0); } }

.header-desc__item:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: #000000;
  background: #f2f3f4;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 7s infinite linear alternate-reverse; }

@keyframes noise-anim-2 {
  0% {
    clip: rect(15px, 9999px, 3px, 0); }
  10% {
    clip: rect(41px, 9999px, 3px, 0); }
  20% {
    clip: rect(98px, 9999px, 2px, 0); }
  30% {
    clip: rect(6px, 9999px, 57px, 0); }
  40% {
    clip: rect(43px, 9999px, 59px, 0); }
  50% {
    clip: rect(1px, 9999px, 27px, 0); }
  60% {
    clip: rect(49px, 9999px, 98px, 0); }
  70% {
    clip: rect(85px, 9999px, 79px, 0); }
  80% {
    clip: rect(53px, 9999px, 94px, 0); }
  90% {
    clip: rect(96px, 9999px, 87px, 0); }
  100% {
    clip: rect(22px, 9999px, 53px, 0); } }

.header-desc__item:before {
  content: attr(data-text);
  position: absolute;
  text-shadow: 1px 0 blue;
  top: 0;
  color: #000000;
  background: #f2f3f4;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 8s infinite linear alternate-reverse; }

/***********************************************************************************/
@keyframes scroll {
  0% {
    background-position: 50% 0%; }
  20% {
    background-position: 50% 50%; }
  40% {
    background-position: 50% 80%; }
  60% {
    background-position: 50% 100%; }
  80% {
    background-position: 50% 0%; }
  100% {
    background-position: 50% 0%; } }

@keyframes scroll1 {
  0% {
    background-position: 50% 0%; }
  40% {
    background-position: 50% 100%; }
  60% {
    background-position: 50% 100%; }
  80% {
    background-position: 50% 0%; }
  100% {
    background-position: 50% 0%; } }

@media (max-width: 1250px) {
  .header-desc__item {
    font-size: 41px; }
  .header-laptop {
    left: 40%; } }

@media (max-width: 1045px) {
  .header-laptop {
    width: calc(616px * 0.75);
    height: calc(372px * 0.75);
    left: 50%; }
    .header-laptop__page {
      width: 343px;
      height: 214px;
      top: 16px;
      left: 61px; }
    .header-laptop__link {
      width: 300px;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px; } }

@media (max-width: 991px) {
  .header-laptop {
    width: calc(616px * 0.7);
    height: calc(372px * 0.7);
    left: 42.5%; }
    .header-laptop__page {
      width: 320px;
      height: 200px;
      top: 15px;
      left: 57px; }
    .header-laptop__page-wrap {
      margin-bottom: 10px; }
  .header-desc__item {
    font-size: 33px;
    margin: 10px 5px 10px 5px; } }

@media (max-width: 767px) {
  .header {
    min-height: 637px;
    overflow: hidden; }
    .header .big-logo {
      display: block;
      margin: 0 auto;
      width: 171px;
      height: auto;
      margin-top: 60px; }
    .header-desc {
      width: 100%;
      position: absolute;
      bottom: 40px;
      left: 0; }
      .header-desc__inner {
        width: 307px;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center; }
      .header-desc__item {
        line-height: 38px;
        margin: 0; }
        .header-desc__item:after {
          width: 100%; }
      .header-desc :not(:last-child) {
        margin: 0; }
      .header-desc__item:first-child {
        margin-right: 8px; }
    .header-laptop {
      width: calc(616px * 0.45);
      height: calc(372px * 0.45);
      top: 170px;
      left: 50%;
      transform: translateX(-50%); }
      .header-laptop__page {
        width: 206px;
        height: 129px;
        top: 9px;
        left: 36px; }
      .header-laptop__page-wrap {
        margin-bottom: 20px; }
      .header-laptop__link {
        width: 250px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px; }
    .header .note-img-1 {
      background-image: url(../../../../img/sites/load1.webp); }
    .header .note-img-2 {
      background-image: url(../../../../img/sites/car1.webp); }
    .header .note-img-3 {
      background-image: url(../../../../img/sites/neitron1.webp); }
    .header .note-img-4 {
      background-image: url(../../../../img/sites/trade1.webp); }
    .header .note-img-5 {
      background-image: url(../../../../img/sites/victoria1.webp); } }
  @media screen and (max-width: 767px) and (min-width: 0\0) {
    .header .note-img-1 {
      background-image: url(../../../../img/sites/load1.png); }
    .header .note-img-2 {
      background-image: url(../../../../img/sites/car1.png); }
    .header .note-img-3 {
      background-image: url(../../../../img/sites/neitron1.png); }
    .header .note-img-4 {
      background-image: url(../../../../img/sites/trade1.png); }
    .header .note-img-5 {
      background-image: url(../../../../img/sites/victoria1.png); } }

@media (max-width: 540px) {
  .header {
    min-height: 600px;
    padding: 100px 0 45px; }
    .header .big-logo {
      margin-top: 0px; }
    .header-laptop {
      top: 205px; }
    .header-desc {
      bottom: 30px; } }

@media (max-width: 450px) {
  .header-desc__item :nth-child(2) {
    margin-left: 20%; } }
