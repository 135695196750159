.wrap404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; }
  .wrap404 .picture {
    margin: 50px 0 30px;
    display: flex;
    justify-content: center;
    width: 100%; }
