.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.botton {
  text-align: center;
  max-width: 344px;
  font-size: 20px;
  padding: 10px 0;
  color: #242d3c;
  font-weight: 550;
  border: 1px solid #000000;
  line-height: 36px;
  cursor: pointer;
  margin: 0 auto; }
