/*global margins, between sections*/
.g-margin-xs {
  margin-bottom: 40px; }
  @media (max-width: 575.98px) {
    .g-margin-xs {
      margin-bottom: 30px; } }

.g-margin-s {
  margin-bottom: 70px; }
  @media (max-width: 575.98px) {
    .g-margin-s {
      margin-bottom: 50px; } }

.g-margin-m {
  margin-bottom: 80px; }
  @media (max-width: 767.98px) {
    .g-margin-m {
      margin-bottom: 50px; } }
  @media (max-width: 575.98px) {
    .g-margin-m {
      margin-bottom: 30px; } }

.g-margin-l {
  margin-bottom: 100px; }

.g-margin-xl {
  margin-bottom: 130px; }
  @media (max-width: 1199.98px) {
    .g-margin-xl {
      margin-bottom: 120px; } }
  @media (max-width: 991.98px) {
    .g-margin-xl {
      margin-bottom: 100px; } }
  @media (max-width: 767.98px) {
    .g-margin-xl {
      margin-bottom: 70px; } }
  @media (max-width: 575.98px) {
    .g-margin-xl {
      margin-bottom: 38px; } }

.g-margin-xxxl {
  margin-bottom: 170px; }
