.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.seo-animation {
  position: relative;
  width: 200px;
  height: 143px; }
  .seo-animation .leptop-botom {
    position: absolute;
    top: 134px;
    left: 23px;
    width: 154px;
    height: 10px;
    border-radius: 0 0 5px 5px;
    background-color: #cccbca; }
  .seo-animation .leptop-line {
    position: absolute;
    top: 135px;
    left: 23px;
    width: 154px;
    height: 4px;
    background-color: #bcbcbc;
    border-radius: 10px 10px 0 0; }
  .seo-animation .leptop-scrin {
    position: absolute;
    top: 51px;
    left: 37px;
    width: 127px;
    height: 85px;
    border-radius: 5px 5px 0 0;
    background-color: #cccbca;
    z-index: 1; }
  .seo-animation .leptop-scrin1 {
    position: absolute;
    top: 57px;
    left: 44px;
    width: 112px;
    height: 72px;
    background-color: #ffffff;
    z-index: 1; }

.dot1 {
  position: absolute;
  top: 88px;
  left: 29px;
  width: 5px;
  height: 5px;
  transform: translateX(9px);
  background-color: #9d9d9d; }

.seo-animation-start .slide-left-dot1 {
  animation: slide-left-dot1 1300ms linear forwards; }

@keyframes slide-left-dot1 {
  0% {
    transform: translateX(9px); }
  100% {
    transform: translateX(0px); } }

.dot2 {
  position: absolute;
  top: 62px;
  left: 29px;
  width: 5px;
  height: 5px;
  transform: translateX(30px) translateY(40px);
  background-color: #9d9d9d; }

.dot3 {
  position: absolute;
  top: 62px;
  left: 22px;
  width: 5px;
  height: 5px;
  transform: translateX(30px) translateY(40px);
  background-color: #9d9d9d; }

.dot4 {
  position: absolute;
  top: 55px;
  left: 22px;
  width: 5px;
  height: 5px;
  transform: translateX(30px) translateY(40px);
  background-color: #9d9d9d; }

.dot5 {
  position: absolute;
  top: 48px;
  left: 22px;
  width: 5px;
  height: 5px;
  transform: translateX(30px) translateY(40px);
  background-color: #9d9d9d; }

.dot6 {
  position: absolute;
  top: 43px;
  left: 98px;
  width: 5px;
  height: 5px;
  transform: translateY(68px);
  background-color: #9d9d9d; }

.dot7 {
  position: absolute;
  top: 36px;
  left: 98px;
  width: 5px;
  height: 5px;
  transform: translateY(68px);
  background-color: #9d9d9d; }

.dot8 {
  position: absolute;
  top: 29px;
  left: 98px;
  width: 5px;
  height: 5px;
  transform: translateY(68px);
  background-color: #9d9d9d; }

.dot9 {
  position: absolute;
  top: 62px;
  left: 166px;
  width: 5px;
  height: 5px;
  transform: translateX(-15px);
  background-color: #9d9d9d; }

.seo-animation-start .slide-right-top-dot9 {
  animation: slide-right-top-dot9 4000ms linear forwards; }

@keyframes slide-right-top-dot9 {
  0% {
    transform: translateX(-15px); }
  10% {
    transform: translateX(-15px); }
  30% {
    transform: translateX(0px); }
  100% {
    transform: translateX(0px); } }

.dot10 {
  position: absolute;
  top: 62px;
  left: 173px;
  width: 5px;
  height: 5px;
  transform: translateX(-15px);
  background-color: #9d9d9d; }

.seo-animation-start .slide-right-top-dot10 {
  animation: slide-right-top-dot10 3000ms linear forwards; }

@keyframes slide-right-top-dot10 {
  0% {
    transform: translateX(-15px); }
  10% {
    transform: translateX(-15px); }
  30% {
    transform: translateX(0px); }
  100% {
    transform: translateX(0px); } }

.dot11 {
  position: absolute;
  top: 55px;
  left: 173px;
  width: 5px;
  height: 5px;
  transform: translateX(-15px) translateY(7px);
  background-color: #9d9d9d; }

.seo-animation-start .slide-right-top-dot11 {
  animation: slide-right-top-dot11 1500ms linear forwards; }

@keyframes slide-right-top-dot11 {
  0% {
    transform: translateX(-15px) translateY(7px); }
  10% {
    transform: translateX(-15px) translateY(7px); }
  40% {
    transform: translateX(0px) translateY(7px); }
  100% {
    transform: translateX(0px) translateY(0px); } }

.dot12 {
  position: absolute;
  top: 48px;
  left: 173px;
  width: 5px;
  height: 5px;
  transform: translateX(-15px) translateY(14px);
  background-color: #9d9d9d; }

.seo-animation-start .slide-right-top-dot12 {
  animation: slide-right-top-dot12 1200ms linear forwards; }

@keyframes slide-right-top-dot12 {
  0% {
    transform: translateX(-15px) translateY(14px); }
  10% {
    transform: translateX(-15px) translateY(14px); }
  40% {
    transform: translateX(0px) translateY(14px); }
  100% {
    transform: translateX(0px) translateY(0px); } }

.dot13 {
  position: absolute;
  top: 79px;
  left: 166px;
  width: 5px;
  height: 5px;
  background-color: #9d9d9d; }

.dot14 {
  position: absolute;
  top: 79px;
  left: 173px;
  width: 5px;
  height: 5px;
  background-color: #9d9d9d; }

.dot15 {
  position: absolute;
  top: 79px;
  left: 180px;
  width: 5px;
  height: 5px;
  background-color: #9d9d9d; }

.dot16 {
  position: absolute;
  top: 86px;
  left: 180px;
  width: 5px;
  height: 5px;
  background-color: #9d9d9d; }

.blackblcok1 {
  position: absolute;
  top: 78px;
  left: 38px;
  width: 27px;
  height: 27px;
  background-color: #242d3c; }

.seo-animation-start .slide-left-bottom {
  animation: slide-left-bottom 800ms linear forwards; }

@keyframes slide-left-bottom {
  0% {
    left: 38px; }
  100% {
    left: 0; } }

.blackblcok2 {
  position: absolute;
  top: 92px;
  left: 169px;
  width: 27px;
  height: 27px;
  background-color: #242d3c; }

.seo-animation-start .blackblcok2-animation {
  animation: blackblcok2-animation 2000ms linear forwards; }

@keyframes blackblcok2-animation {
  0% {
    height: 5px; }
  60% {
    height: 5px; }
  100% {
    height: 27px; } }

.redcircle1 {
  position: absolute;
  top: 16px;
  left: 10px;
  transform: translateX(30px) translateY(40px);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #f15424; }

.seo-animation-start .slide-left-top {
  animation: slide-left-top 1100ms linear forwards; }

@keyframes slide-left-top {
  0% {
    transform: translateX(30px) translateY(40px); }
  40% {
    transform: translateX(30px) translateY(0px); }
  100% {
    transform: translateX(0px) translateY(0px); } }

.redcircle2 {
  position: absolute;
  top: 14px;
  left: 160px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: translateX(-30px) translateY(38px);
  background-color: #f15424; }

.seo-animation-start .slide-right-top {
  animation: slide-right-top 1100ms linear forwards; }

@keyframes slide-right-top {
  0% {
    transform: translateX(-30px) translateY(38px); }
  40% {
    transform: translateX(0px) translateY(38px); }
  100% {
    transform: translateX(0px) translateY(0px); } }

.blueblcok {
  position: absolute;
  top: 0;
  left: 87px;
  width: 27px;
  height: 26px;
  transform: translateY(68px);
  background-color: #27b2f3;
  z-index: 0; }

.seo-animation-start .slide-top-center {
  animation: slide-top-center 1000ms linear forwards; }

@keyframes slide-top-center {
  0% {
    transform: translateY(68px); }
  100% {
    transform: translateY(0); } }

.bluecircle {
  position: absolute;
  top: 63px;
  left: 76px;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: #27b2f3;
  z-index: 1; }

.redblock1 {
  position: absolute;
  top: 63px;
  left: 48px;
  width: 21px;
  height: 12px;
  background-color: #f15424;
  z-index: 1; }

.blackblcok3 {
  position: absolute;
  top: 116px;
  left: 80px;
  width: 17px;
  height: 6px;
  background-color: #242d3c;
  z-index: 1; }

.redblock2 {
  position: absolute;
  top: 116px;
  left: 104px;
  width: 17px;
  height: 6px;
  background-color: #f15424;
  z-index: 1; }

.left-text1 {
  position: absolute;
  top: 80px;
  left: 48px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.left-text2 {
  position: absolute;
  top: 84px;
  left: 48px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.left-text3 {
  position: absolute;
  top: 88px;
  left: 48px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.left-text4 {
  position: absolute;
  top: 92px;
  left: 48px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.left-text5 {
  position: absolute;
  top: 96px;
  left: 48px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.right-text1 {
  position: absolute;
  top: 63px;
  left: 131px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.right-text2 {
  position: absolute;
  top: 67px;
  left: 131px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.right-text3 {
  position: absolute;
  top: 71px;
  left: 131px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.right-text4 {
  position: absolute;
  top: 75px;
  left: 131px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.right-text5 {
  position: absolute;
  top: 79px;
  left: 131px;
  width: 21px;
  height: 3px;
  background-color: #242d3c;
  z-index: 1; }

.slide-right-bottom-wrapper {
  transform: translateX(-68px); }

.seo-animation-start .slide-right-bottom-black {
  animation: slide-right-bottom-black 1s linear both; }

@keyframes slide-right-bottom-black {
  0% {
    transform: translateX(-68px); }
  100% {
    transform: translateX(0); } }

.seo-animation-start .infiniti-antena {
  animation: infiniti-antena 5s linear infinite both;
  animation-delay: 5s; }

@keyframes infiniti-antena {
  0%, 10%, 100% {
    transform: translateX(0) rotate(0); }
  2% {
    transform: translateX(-15px) rotate(-8deg); }
  4% {
    transform: translateX(7.5px) rotate(8deg); }
  6% {
    transform: translateX(-7.5px) rotate(-4deg); }
  8% {
    transform: translateX(4.5px) rotate(3deg); } }
