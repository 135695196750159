.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.ServicesBlock {
  font: 15px Montserrat, arial, sans-serif; }
  .ServicesBlock .header-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
    .ServicesBlock .header-title h2 {
      margin-bottom: 50px;
      color: #000000;
      font-size: 36px;
      font-weight: 700;
      line-height: 42.04px;
      text-transform: uppercase;
      border-top: 2px solid #27b2f3;
      padding-top: 20px; }
  .ServicesBlock .wrapper-services {
    margin-bottom: 50px;
    height: 225px; }
  .ServicesBlock .wrapper-services:nth-child(3),
  .ServicesBlock .wrapper-services:nth-child(4) {
    height: 280px; }
  .ServicesBlock .services-item {
    height: 100%;
    min-height: 250px;
    width: 100%;
    padding: 42px 50px 0;
    text-align: center;
    box-shadow: 4px -9px 35px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    background-color: #ffffff;
    border-bottom: 3px solid #27b2f3; }
  .ServicesBlock .title {
    margin-bottom: 30px;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    text-transform: uppercase; }
  .ServicesBlock .description {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px; }
  @media (max-width: 1200px) {
    .ServicesBlock .description {
      font-size: 19px; } }
  @media (max-width: 991.98px) {
    .ServicesBlock .wrapper-services {
      height: 320px; }
    .ServicesBlock .wrapper-services:nth-child(3),
    .ServicesBlock .wrapper-services:nth-child(4) {
      height: 380px; } }
  @media (max-width: 767.98px) {
    .ServicesBlock .header-title {
      justify-content: center; }
      .ServicesBlock .header-title h2 {
        font-size: 30px;
        display: block;
        text-align: center;
        border-width: 6px;
        padding-top: 8px; }
    .ServicesBlock .wrapper-services {
      height: 100%; }
    .ServicesBlock .wrapper-services:nth-child(3),
    .ServicesBlock .wrapper-services:nth-child(4) {
      height: 100%; }
    .ServicesBlock .services-item {
      height: 100%;
      min-height: auto;
      padding-bottom: 20px;
      padding-top: 20px; }
    .ServicesBlock .title {
      margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    .ServicesBlock .title {
      margin-bottom: 0; }
    .ServicesBlock .description {
      font-size: 18px; }
    .ServicesBlock .services-item {
      padding: 10px 15px; } }
  @media (max-width: 400px) {
    .ServicesBlock {
      margin-bottom: 33px !important; }
      .ServicesBlock .services-item {
        padding: 32px 18px; }
      .ServicesBlock .title {
        font-size: 24px;
        font-weight: normal; }
      .ServicesBlock .wrapper-services {
        margin-bottom: 16px;
        padding-left: 18px;
        padding-right: 18px; } }
