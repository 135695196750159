.offer-page {
  margin: 70px auto;
  padding-bottom: 70px;
  width: 1200px;
  display: flex; }
  .offer-page p {
    margin: 15px 0 !important; }
  @media screen and (max-width: 1200px) {
    .offer-page {
      margin: 70px 5px;
      width: auto; } }
  @media screen and (max-width: 768px) {
    .offer-page {
      margin: 35px 15px;
      padding-bottom: 35px; } }
