.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.wrap_rel {
  position: relative;
  width: 100%;
  height: 100%; }

.design-animation {
  position: relative;
  width: 200px;
  height: 174px; }

.hat_r {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 14px;
  background-color: #242d3c; }

.head_r {
  position: absolute;
  top: 67px;
  left: 39px;
  width: 120px;
  height: 67px;
  background-color: #ffffff;
  opacity: 0; }

.design-animation-start .head_r {
  animation-name: head-start;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.eye_wrap {
  position: absolute;
  top: 22px;
  left: 9px;
  width: 103px;
  height: 13px;
  background-color: #27b2f3; }

.eye_r {
  position: absolute;
  top: 27px;
  width: 12px;
  height: 3px;
  background-color: #242d3c; }

.eye_1 {
  left: 20px; }

.eye_2 {
  left: 43px; }

.eye_3 {
  left: 66px; }

.design-animation-start .eye_3,
.design-animation-start .eye_4 {
  animation-duration: 45s;
  animation-delay: 4s;
  animation-name: clip;
  animation-iteration-count: infinite; }

.eye_4 {
  left: 89px; }

.nose_r {
  position: absolute;
  top: 42px;
  left: 9px;
  width: 103px;
  height: 7px;
  background-color: #27b2f3; }

.mouth_r {
  position: absolute;
  top: 55px;
  left: 26px;
  width: 69px;
  height: 5px;
  background-color: #f15424; }

.stomach_r {
  position: absolute;
  top: 67px;
  left: 39px;
  width: 120px;
  height: 54px;
  background-color: #27b2f3;
  z-index: 2; }

.picture_r {
  position: absolute;
  top: 83px;
  left: 74px;
  width: 46px;
  height: 24px;
  background-image: url(../../../../../img/picture_r.png);
  z-index: 3; }

/*************************************   LEGS   *******************************/
.legs-start {
  position: absolute;
  opacity: 0; }

.design-animation-start .legs-start {
  animation-name: legs-start;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.leg_r {
  position: absolute;
  top: 0;
  width: 30px;
  height: 46px; }

.design-animation-start .leg_r {
  animation-duration: 45s;
  animation-delay: 6s;
  animation-iteration-count: infinite; }

.leg_1 {
  left: 48px; }

.design-animation-start .leg_1 {
  animation-name: move_r1; }

.leg_2 {
  left: 84px; }

.design-animation-start .leg_2 {
  animation-name: move_r2; }

.leg_3 {
  left: 121px; }

.design-animation-start .leg_3 {
  animation-name: move_l; }

.hip_r {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 22px;
  background-color: #f15424; }

.knee_r {
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: #6f6f6f; }

.knee_1 {
  top: 24px;
  left: 0; }

.knee_2 {
  top: 30px;
  left: 0; }

.knee_3 {
  top: 36px;
  left: 0;
  width: 15px; }

/*************************************   LEFT HAND   *******************************/
.hand_left {
  position: absolute;
  width: 34px;
  height: 27px;
  background-color: #27b2f3;
  transform-origin: bottom right; }

.hand_left-start {
  position: absolute;
  opacity: 0; }

.design-animation-start .hand_left-start {
  animation-name: hand_start;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.design-animation-start .hand_left {
  animation-name: punch;
  animation-delay: 6s;
  animation-duration: 45s;
  animation-iteration-count: infinite; }

.hand_left-str {
  position: absolute;
  left: 3px;
  width: 27px;
  height: 3px;
  background-color: #ffffff; }

.hand_left1 {
  top: 6px; }

.hand_left2 {
  top: 11px; }

.hand_left3 {
  top: 16px;
  width: 14px; }

/*************************************   RIGHT HAND   *******************************/
.hand_right {
  position: absolute;
  top: 73px;
  left: 140px;
  width: 54px;
  height: 30px;
  background-color: #ffffff;
  z-index: 4;
  transform: scale(0); }

.design-animation-start .hand_right {
  animation-name: hand_r-start;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.hand_right-1 {
  position: absolute;
  top: 21px;
  left: 7px;
  width: 7px;
  height: 5px;
  background-color: #242d3c; }

.hand_right-2 {
  position: absolute;
  top: 14px;
  left: 15px;
  width: 7px;
  height: 12px;
  background-color: #bcbcbc; }

.hand_right-3 {
  position: absolute;
  top: 10px;
  left: 23px;
  width: 7px;
  height: 16px;
  background-color: #bcbcbc; }

.hand_right-4 {
  position: absolute;
  top: 5px;
  left: 39px;
  width: 7px;
  height: 21px;
  background-color: #f15424; }

.hand_right-5 {
  position: absolute;
  top: 14px;
  left: 31px;
  width: 8px;
  height: 12px;
  background-color: #bcbcbc; }

/*************************************   UP HAND   *******************************/
.hand_up {
  position: absolute;
  width: 33px;
  height: 14px;
  opacity: 0; }

.design-animation-start .hand_up {
  animation-name: hand_up-start;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.hand_up1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 27px;
  height: 4px;
  background-color: #6f6f6f; }

.hand_up2 {
  position: absolute;
  top: 5px;
  left: 0;
  width: 27px;
  height: 4px;
  background-color: #6f6f6f; }

.hand_up3 {
  position: absolute;
  top: 10px;
  left: 0;
  width: 33px;
  height: 4px;
  background-color: #6f6f6f; }

@media (max-width: 991px) {
  .hand_left {
    animation-name: punch_v; } }

/***************************      legs animation     **********************/
@keyframes legs-start {
  0% {
    top: 82px;
    opacity: 0.9; }
  100% {
    top: 128px;
    opacity: 1; } }

@keyframes move_r1 {
  0% {
    left: 48px;
    top: 0;
    transform: rotate(0); }
  1% {
    left: 53px;
    top: -7px;
    transform: rotate(-45deg); }
  2% {
    left: 48px;
    top: 0;
    transform: rotate(0); }
  47% {
    left: 48px;
    top: 0;
    transform: rotate(0); }
  48% {
    left: 53px;
    top: -7px;
    transform: rotate(-45deg); }
  49% {
    left: 48px;
    top: 0;
    transform: rotate(0); }
  90% {
    left: 48px;
    top: 0;
    transform: rotate(0); }
  91% {
    left: 53px;
    top: -7px;
    transform: rotate(-45deg); }
  92% {
    left: 48px;
    top: 0;
    transform: rotate(0); } }

@keyframes move_r2 {
  24% {
    left: 84px;
    top: 0;
    transform: rotate(0); }
  25% {
    left: 90px;
    top: -7px;
    transform: rotate(-45deg); }
  26% {
    left: 84px;
    top: 0;
    transform: rotate(0); }
  87% {
    left: 84px;
    top: 0;
    transform: rotate(0); }
  88% {
    left: 90px;
    top: -7px;
    transform: rotate(-45deg); }
  89% {
    left: 84px;
    top: 0;
    transform: rotate(0); } }

@keyframes move_l {
  9% {
    left: 121px;
    top: 0;
    transform: rotate(0); }
  10% {
    left: 129px;
    top: -13px;
    transform: rotate(-65deg); }
  11% {
    left: 121px;
    top: 0;
    transform: rotate(0); }
  39% {
    left: 121px;
    top: 0;
    transform: rotate(0); }
  40% {
    left: 129px;
    top: -13px;
    transform: rotate(-65deg); }
  41% {
    left: 121px;
    top: 0;
    transform: rotate(0); }
  72% {
    left: 121px;
    top: 0;
    transform: rotate(0); }
  73% {
    left: 129px;
    top: -13px;
    transform: rotate(-65deg); }
  74% {
    left: 121px;
    top: 0;
    transform: rotate(0); }
  /******************************* end legs animation ************************/ }

@keyframes clip {
  13% {
    top: 27px;
    height: 3px; }
  14% {
    top: 30px;
    height: 1px; }
  15% {
    top: 27px;
    height: 3px; }
  35% {
    top: 27px;
    height: 3px; }
  36% {
    top: 30px;
    height: 1px; }
  37% {
    top: 27px;
    height: 3px; }
  55% {
    top: 27px;
    height: 3px; }
  56% {
    top: 30px;
    height: 1px; }
  57% {
    top: 27px;
    height: 3px; } }

@keyframes hand_start {
  0% {
    top: 68px;
    left: 40px;
    opacity: 0.9; }
  50% {
    top: 68px;
    left: 0; }
  100% {
    top: 40px;
    left: 0;
    opacity: 1; } }

@keyframes punch {
  21% {
    transform: rotate(0); }
  22% {
    transform: rotate(-45deg); }
  23% {
    transform: rotate(0); }
  41% {
    transform: rotate(0); }
  42% {
    transform: rotate(-45deg); }
  43% {
    transform: rotate(0); }
  71% {
    transform: rotate(0); }
  72% {
    transform: rotate(-45deg); }
  73% {
    transform: rotate(0); }
  96% {
    transform: rotate(0); }
  97% {
    transform: rotate(-45deg); }
  98% {
    transform: rotate(0); } }

@keyframes hand_r-start {
  0% {
    transform: scale(0.4); }
  100% {
    transform: scale(1); } }

@keyframes head-start {
  0% {
    top: 67px;
    opacity: 0.9; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes hand_up-start {
  0% {
    top: 70px;
    left: 120px;
    opacity: 0.9; }
  50% {
    top: 70px;
    left: 120px;
    opacity: 1; }
  75% {
    top: 62px;
    left: 207px;
    opacity: 0.9; }
  100% {
    top: 42px;
    left: 167px;
    opacity: 1; } }
