/*----------------------------------------------------------------------------------//
//------------------------------RESET DEFAULT STYLES--------------------------------//
//----------------------------------------------------------------------------------*/
html,
body {
  width: 100%;
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  font: 15px Montserrat, arial, sans-serif;
  color: #000;
}
div,
p,
a,
span,
em,
strong,
img,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
td,
tr,
form,
fieldset,
label,
select,
input,
textarea {
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}
select,
input,
textarea {
  font-size: 15px;
  vertical-align: middle;
}
textarea {
  vertical-align: top;
}
:focus {
  outline: none;
}
input[type="submit"] {
  cursor: pointer;
}
img,
fieldset {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a:link,
a:visited,
a:hover {
  font: 16px Montserrat, arial, sans-serif;
  color: #000;
  text-decoration: none;
}
