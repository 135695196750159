.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.cart {
  max-height: 386px;
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex; }
  .cart:not(:last-child) {
    border-bottom: 1px solid #5ba2c2; }
  .cart__img {
    max-height: 346px; }
  .cart__img:hover {
    box-shadow: 4px 4px 10px rgba(175, 175, 175, 0.56);
    border-radius: 4px; }
  .cart__img img {
    max-height: 346px;
    max-width: 100%; }
  .cart__description {
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 68px;
    max-width: 553px; }
  .cart__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 4px; }
  .cart__subtitle {
    font-size: 18px;
    color: #4a4a4a;
    font-weight: 300;
    margin-bottom: 16px;
    opacity: 0.8; }
    .cart__subtitle p {
      margin-bottom: 0; }
  .cart__text {
    margin-bottom: 18px;
    font-size: 20px;
    color: #000000;
    line-height: 160%; }
  .cart__technology {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px; }
    .cart__technology div {
      font-size: 12px;
      background-color: #e7e7e7;
      color: #393939;
      padding: 2px 16px;
      border-radius: 100px;
      margin-right: 8px;
      margin-bottom: 8px; }
  .cart__link a {
    color: #27b2f3 !important; }
    .cart__link a:hover {
      font-weight: 500 !important;
      font-size: 16px !important; }

@media (max-width: 1000px) {
  .cart {
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 100%;
    padding-bottom: 20px; }
    .cart__description {
      margin-bottom: 0px;
      margin-left: 0px; } }
