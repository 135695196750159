@import "../../../components/App/variables.scss";
img {
  width: 100%;
  height: 100%;
}
.container {
  max-width: 1140px;
  margin: 0 auto;
}
.carenty {
  &-main {
    background: url("../../../img/projects/carenty/unsplash_dbLgODXOPgo.png") 0 0/100% 100% no-repeat;

    &__inner {
      display: flex;
      padding-top: 160px;
      // padding-bottom: 75px;
    }

    &__content {
      max-width: 530px;
      margin-left: 60px;
      order: 2;
    }
    &__image {
      order: 1;
    }
    &__title {
      font-weight: bold;
      font-size: 76px;
      color: white;
      margin-bottom: 40px;
    }
    &__text {
      color: #fcfcfc;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 40px;
      opacity: 0.8;
    }
    &__button {
      text-align: center;
      max-width: 344px;
      font-size: 16px;
      padding: 10px 0;
      color: $color-dark-blue;
      font-weight: 500;
      line-height: 36px;
      cursor: pointer;
      background: #fcfcfc;
      margin-bottom: 20px;
    }
    &__image {
      margin-bottom: -42px;
    }
  }
  &-project {
    margin-top: 172px;
  }
  &-work {
    &__item {
      list-style: inside;
      &:first-child {
        margin-top: 40px;
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &__list {
      margin-bottom: 40px;
    }
  }
  &-aim {
    &__title {
      margin-bottom: 60px;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      display: flex;
      margin-bottom: 80px;
    }
    &__box {
      max-width: 550px;
      &:first-child {
        margin-right: 40px;
      }
    }
    &__text {
      max-width: 366px;
      margin-left: 40px;
      font-size: 16px;
      line-height: 25px;
      opacity: 0.8;
      align-self: center;
    }
    &__image {
      height: 80px;
      width: 80px;
    }
  }
  &-team {
    background: #272323;
    &__title {
      font-size: 50px;
      font-weight: bold;
      color: white;
      padding-top: 128px;
      padding-bottom: 80px;
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 108px;
    }
    &__number {
      width: 88px;
      &:not(:last-child) {
        margin-right: 165px;
      }
      margin-bottom: 20px;
    }
    &__image {
      display: block;
      height: 166px;
      margin-bottom: 25px;
    }
    &__image {
      width: 88px;
    }
    &__text {
      display: flex;
      justify-content: flex-end;
      text-align: center;
      color: white;
      font-weight: 500;
      line-height: 25px;
    }
  }
}
@media (max-width: 1000px) {
  .carenty {
    &-main {
      &__inner {
        flex-wrap: wrap;
        flex-direction: column;
        padding-top: 100px;
      }

      &__image {
        display: block;
        margin-bottom: 60px;
      }
    }
  }
}
