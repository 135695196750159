$color-white: #ffffff;
$color-white1: #cccbca;
$color-white2: #bcbcbc;
$color-gray: #b3b3b3;
$color-gray1: #9d9d9d;
$color-gray2: #f2f3f4;
$color-gray3: #c2c2c2;
$color-gray4: #6f6f6f;
$color-gray5: #aeaeae;
$color-gray6: #4a4a4a;
$color-accent: #27b2f3;
$color-accent2: #0093d7;
$color-dark-blue: #242d3c;
$color-dark-red: #f15424;
$color-black: #000000;

.text {
    max-width: 930px;
    font-weight: 500;
    font-size: 20px;
    font-family: "Montserrat";
    line-height: 32px;
    color: #272727;
    opacity: 0.8;
}

.title {
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    color: $color-black;
    font-family: "Montserrat";
}
