.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.ServicesImg {
  font: 15px Montserrat, arial, sans-serif;
  color: black; }
  .ServicesImg .header-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
    .ServicesImg .header-title h2 {
      margin-bottom: 10px;
      color: #000000;
      font-size: 36px;
      font-weight: 700;
      line-height: 42.04px;
      text-transform: uppercase;
      border-top: 2px solid #27b2f3;
      padding-top: 20px; }
  .ServicesImg .description {
    padding-right: 180px; }
  .ServicesImg .web {
    margin-top: 35px !important;
    margin-bottom: 0px !important; }
  .ServicesImg .seo {
    margin-top: 50px !important;
    margin-bottom: 20px !important; }
  .ServicesImg .des {
    margin-top: 50px !important;
    margin-bottom: 30px !important; }
  .ServicesImg .animation_wrap {
    margin-top: -20px;
    display: flex;
    flex-direction: column; }
  .ServicesImg .work_section {
    display: none; }
    .ServicesImg .work_section .wrapper {
      margin: 0 auto;
      width: 95vw;
      display: flex;
      flex-direction: column; }
      .ServicesImg .work_section .wrapper .exp {
        position: relative;
        height: 250px;
        width: 90vw;
        margin: 20px auto 20px auto;
        display: flex;
        overflow: hidden; }
        .ServicesImg .work_section .wrapper .exp .header {
          background-color: #f9f9f9;
          position: absolute;
          top: 0;
          width: 20%;
          height: 250px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          color: #a0a0a0; }
          .ServicesImg .work_section .wrapper .exp .header .text {
            position: absolute;
            width: 250px;
            font-size: 24px;
            height: auto;
            transform: rotate(-90deg);
            display: flex;
            justify-content: center; }
        .ServicesImg .work_section .wrapper .exp .description {
          padding: 10px 0;
          width: 80%;
          height: 250px;
          background-color: #f9f9f9;
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 0; }
          .ServicesImg .work_section .wrapper .exp .description p {
            font-size: 20px;
            margin-bottom: 5px;
            text-align: left; }
        .ServicesImg .work_section .wrapper .exp .animation_block {
          position: absolute;
          text-align: center;
          height: 300px;
          width: 200px;
          margin-left: calc(-100px + 25%); }
        .ServicesImg .work_section .wrapper .exp .animation_block_1 {
          bottom: -100%;
          left: 30%; }
        .ServicesImg .work_section .wrapper .exp .animation_block_2 {
          bottom: -75%;
          left: 25%; }
        .ServicesImg .work_section .wrapper .exp .animation_block_3 {
          bottom: -45%;
          left: 25%; }
  .ServicesImg .container .title,
  .ServicesImg .container .description,
  .ServicesImg .container .animation_block {
    text-align: center; }
  .ServicesImg .container .item {
    width: 99%;
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    background-color: #f9f9f9; }
    .ServicesImg .container .item .animation_block {
      padding: 0;
      min-height: 250px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .ServicesImg .container .item .info_block {
      padding: 0;
      min-height: 250px; }
  .ServicesImg .container .column {
    padding-bottom: 20px; }
  .ServicesImg .container .title h3 {
    text-transform: uppercase;
    line-height: 2.1;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px; }
  .ServicesImg .container .description p {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.6; }

/* MEDIA */
@media (max-width: 991px) {
  .ServicesImg .description {
    padding-right: 0px; }
  .ServicesImg .container .column {
    padding-bottom: 0; } }

@media (max-width: 767px) {
  .ServicesImg .container {
    padding-left: 18px;
    padding-right: 18px; }
    .ServicesImg .container .item .animation_block {
      min-height: 204px; }
      .ServicesImg .container .item .animation_block .design-animation {
        margin-top: 32px;
        height: 193px; }
    .ServicesImg .container .header-title {
      justify-content: center; }
      .ServicesImg .container .header-title h2 {
        font-size: 30px;
        display: block;
        text-align: center;
        border-width: 6px;
        padding-top: 8px; }
    .ServicesImg .container .info_block {
      min-height: 50px !important; }
    .ServicesImg .container .animation_wrap {
      margin-top: 24px; }
    .ServicesImg .container .animation_wrap .title {
      order: 2; }
    .ServicesImg .container .description {
      margin: 0px; }
    .ServicesImg .container .title h3 {
      padding-top: 0;
      font-size: 24px;
      line-height: 42px;
      font-weight: normal; }
    .ServicesImg .container .description {
      margin-bottom: 32px; }
      .ServicesImg .container .description p {
        font-size: 19px;
        line-height: 36px;
        margin-bottom: 0; } }

@media (max-width: 380px) {
  .ServicesImg .container .description p {
    font-size: 18px; } }

@media (max-width: 300px) {
  .ServicesImg .work_section .wrapper .exp .animation_block {
    display: none; } }
