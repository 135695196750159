@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url("../../fonts/Montserrat/Montserrat-Regular.eot");
  src: local("Montserrat"), url("../../fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("../../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"),
    url("../../fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
}
@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: local("Montserrat"), url("../../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("../../fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
    url("../../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url("../../fonts/Montserrat/Montserrat-SemiBold.eot");
  src: local("Montserrat"), url("../../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("../../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("../../fonts/Montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/Montserrat/Montserrat-Bold.eot");
  src: local("Montserrat"), url("../../fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../../fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("../../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"),
    url("../../fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
}
