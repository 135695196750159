.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.footerblock {
  background-color: #f2f3f4; }
  .footerblock__desc {
    margin-top: 20px; }
  .footerblock__title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 32px; }
    .footerblock__title h2 {
      color: #000000;
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
      text-transform: uppercase;
      border-top: 2px solid #27b2f3;
      padding-top: 20px; }
  .footerblock__num, .footerblock__email {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px; }
    .footerblock__num p, .footerblock__email p {
      margin-bottom: 0; }
  .footerblock__num {
    margin-bottom: 24px; }
  .footerblock__email {
    margin-bottom: 55px; }
  .footerblock__after {
    margin-top: 135px;
    color: #6f6f6f;
    font-size: 15px;
    font-weight: 400; }

@media (max-width: 763px) {
  .footerblock__desc {
    margin-top: 20px; }
  .footerblock__after {
    display: none; }
  .footerblock__email {
    margin-bottom: 0px; } }

@media (max-width: 540px) {
  .footerblock {
    background-color: white; }
    .footerblock__desc {
      margin-top: 0px; }
    .footerblock__title {
      justify-content: center1; }
    .footerblock__num, .footerblock__email {
      line-height: 19px; }
    .footerblock__num {
      margin-bottom: 16px; } }
