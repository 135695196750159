.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.footer {
  font: 15px Montserrat, arial, sans-serif;
  position: relative; }
  .footer .background-div {
    position: absolute;
    right: 0;
    width: 317px;
    height: 341px;
    background: url("../../../../img/keyboard.png") no-repeat top right;
    z-index: -1; }
  .footer .header-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
    .footer .header-title h2 {
      margin-bottom: 40px;
      color: #000000;
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
      text-transform: uppercase;
      border-top: 2px solid #27b2f3;
      padding-top: 20px; }
  .footer .description {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; }
    .footer .description .offer {
      margin-top: 24px; }
  .footer .first-p {
    margin-bottom: 35px; }
  .footer .phone {
    font-size: 15px;
    cursor: pointer; }
  .footer .third-p {
    margin-top: 35px; }

@-webkit-keyframes autofill {
  to {
    color: #000;
    background: transparent; } }
  .footer .afterfooter {
    color: #6f6f6f;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; }
  @media (max-width: 1430px) {
    .footer .background-div {
      background-position-x: 10vw; } }
  @media (max-width: 767.98px) {
    .footer .background-div {
      display: none; }
    .footer .header-title {
      justify-content: center; }
      .footer .header-title h2 {
        font-size: 30px;
        display: block;
        text-align: center;
        border-width: 6px;
        padding-top: 8px; }
    .footer .afterfooter {
      display: none; } }
  @media (max-width: 400px) {
    .footer #prices-id {
      margin: 0;
      padding: 0; }
    .footer .description {
      width: 288px;
      margin: 0 auto;
      text-align: center; }
      .footer .description div,
      .footer .description a {
        font-size: 19px;
        line-height: 36px; }
      .footer .description .third-p {
        margin-top: 0; }
    .footer .title-footer {
      margin-left: 36px;
      margin-right: 28px; }
    .footer h2:before {
      min-height: 6px; }
    .footer .payform {
      margin-top: 29px; }
      .footer .payform .input {
        margin-left: 28px;
        margin-right: 28px; }
      .footer .payform .request-messages {
        padding-left: 28px; } }
