.text {
  max-width: 930px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
  line-height: 32px;
  color: #272727;
  opacity: 0.8; }

.title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
  font-family: "Montserrat"; }

.web-animation .main_block {
  position: relative;
  width: 200px;
  height: 155px;
  /* Active state */ }
  .web-animation .main_block .order_wheel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 15px;
    top: 10px;
    background-color: #9d9d9d;
    width: 71px;
    height: 67px; }
    .web-animation .main_block .order_wheel .circle_lvl_1 {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -29px;
      margin-top: -29px;
      height: 58px;
      width: 58px;
      border-radius: 50% 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../../../../img/web/order.fw.webp") no-repeat center;
      background-size: 110%;
      transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1); }
    .web-animation .main_block .order_wheel .circle_lvl_2 {
      display: flex;
      align-self: center;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -22px;
      margin-top: -22px;
      background-color: #27B2F3;
      height: 44px;
      width: 44px;
      border-radius: 50% 50%; }
    .web-animation .main_block .order_wheel .circle_lvl_3 {
      display: flex;
      align-self: center;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -11px;
      margin-top: -11px;
      background-color: white;
      height: 22px;
      width: 22px;
      border-radius: 50% 50%; }
    .web-animation .main_block .order_wheel .circle_lvl_4 {
      display: flex;
      align-self: center;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -5px;
      margin-top: -5px;
      background-color: black;
      height: 10px;
      width: 10px;
      border-radius: 50% 50%; }
  .web-animation .main_block .order_page {
    position: absolute;
    right: 0;
    width: 99px;
    height: 155px;
    background-color: #f15424; }
    .web-animation .main_block .order_page .title {
      position: absolute;
      width: 99px;
      height: 15px;
      background-color: #232D3B; }
    .web-animation .main_block .order_page .body_text {
      margin: 0 10px;
      top: 15px;
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around; }
      .web-animation .main_block .order_page .body_text div {
        width: 27px;
        height: 3px;
        margin: 5px 1px; }
      .web-animation .main_block .order_page .body_text .parag.blue {
        background-color: #232D3B; }
      .web-animation .main_block .order_page .body_text .parag.white {
        background-color: white; }
      .web-animation .main_block .order_page .body_text .parag.raf_1 {
        width: 17px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_2 {
        width: 27px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_3 {
        width: 54px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_4 {
        width: 31px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_5 {
        width: 12px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_6 {
        width: 27px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_7 {
        width: 18px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_8 {
        width: 56px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_9 {
        width: 40px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_10 {
        width: 12px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_11 {
        width: 31px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_12 {
        width: 12px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_13 {
        width: 27px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_14 {
        width: 18px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_15 {
        width: 56px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_16 {
        width: 40px;
        opacity: 0; }
      .web-animation .main_block .order_page .body_text .parag.raf_17 {
        width: 12px;
        opacity: 0; }
  .web-animation .main_block .order_page:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 99px;
    height: 100px;
    opacity: 1;
    transform: rotate(90deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0) 100%); }
  .web-animation .main_block .order_page:after {
    animation: scanner cubic-bezier(0.39, 0.575, 0.565, 1) infinite both;
    animation-duration: 15s; }
  .web-animation .main_block .order_page:active:after {
    opacity: .5; }
  .web-animation .main_block .order_keyboard {
    position: absolute;
    bottom: 4px;
    height: 63px;
    width: 163px;
    background: url("../../../../../img/web/keyboard.fw.webp") no-repeat center; }
    .web-animation .main_block .order_keyboard .active-buttons .button_1 {
      position: absolute;
      left: 5px;
      top: 5px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_2 {
      position: absolute;
      left: 100px;
      top: 5px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_3 {
      position: absolute;
      left: 97px;
      top: 26px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_4 {
      position: absolute;
      left: 76px;
      top: 26px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_5 {
      position: absolute;
      left: 65px;
      top: 26px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_6 {
      position: absolute;
      left: 55px;
      top: 26px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_7 {
      position: absolute;
      left: 69px;
      top: 36px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_8 {
      position: absolute;
      left: 5px;
      bottom: 7px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_9 {
      position: absolute;
      left: 100px;
      bottom: 7px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_10 {
      position: absolute;
      left: 69px;
      top: 16px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_11 {
      position: absolute;
      left: 79px;
      top: 37px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_12 {
      position: absolute;
      left: 65px;
      top: 26px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_13 {
      position: absolute;
      left: 69px;
      top: 16px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }
    .web-animation .main_block .order_keyboard .active-buttons .button_14 {
      position: absolute;
      left: 55px;
      top: 26px;
      width: 8px;
      height: 8px;
      background-color: #232D3B;
      opacity: 0; }

/*animation active*/
.animation .main_block .order_wheel .circle_lvl_1 {
  animation-name: spin;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.01, 0.01, 0.3, 0.9);
  animation-delay: 0.3s;
  transform-origin: 50% 50%; }

.animation .main_block .order_page .body_text .parag.raf_1 {
  animation: write_text .4s forwards;
  animation-delay: 0.5s; }

.animation .main_block .order_page .body_text .parag.raf_2 {
  animation: write_text .4s forwards;
  animation-delay: 1s; }

.animation .main_block .order_page .body_text .parag.raf_3 {
  animation: write_text .4s forwards;
  animation-delay: 1.5s; }

.animation .main_block .order_page .body_text .parag.raf_4 {
  animation: write_text .4s forwards;
  animation-delay: 2s; }

.animation .main_block .order_page .body_text .parag.raf_5 {
  animation: write_text .4s forwards;
  animation-delay: 2.5s; }

.animation .main_block .order_page .body_text .parag.raf_6 {
  animation: write_text .4s forwards;
  animation-delay: 3s; }

.animation .main_block .order_page .body_text .parag.raf_7 {
  animation: write_text .4s forwards;
  animation-delay: 3.5s; }

.animation .main_block .order_page .body_text .parag.raf_8 {
  animation: write_text .4s forwards;
  animation-delay: 4s; }

.animation .main_block .order_page .body_text .parag.raf_9 {
  animation: write_text .4s forwards;
  animation-delay: 4.5s; }

.animation .main_block .order_page .body_text .parag.raf_10 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_11 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_12 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_13 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_14 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_15 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_16 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_page .body_text .parag.raf_17 {
  animation: write_text .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_keyboard .active-buttons .button_1 {
  animation: keyboard_click .4s forwards;
  animation-delay: 1s; }

.animation .main_block .order_keyboard .active-buttons .button_2 {
  animation: keyboard_click .4s forwards;
  animation-delay: 2.5s; }

.animation .main_block .order_keyboard .active-buttons .button_3 {
  animation: keyboard_click .4s forwards;
  animation-delay: 3s; }

.animation .main_block .order_keyboard .active-buttons .button_4 {
  animation: keyboard_click .4s forwards;
  animation-delay: 3.5s; }

.animation .main_block .order_keyboard .active-buttons .button_5 {
  animation: keyboard_click .4s forwards;
  animation-delay: 4s; }

.animation .main_block .order_keyboard .active-buttons .button_6 {
  animation: keyboard_click .4s forwards;
  animation-delay: 3.2s; }

.animation .main_block .order_keyboard .active-buttons .button_7 {
  animation: keyboard_click .4s forwards;
  animation-delay: 3.8s; }

.animation .main_block .order_keyboard .active-buttons .button_8 {
  animation: keyboard_click .4s forwards;
  animation-delay: 2s; }

.animation .main_block .order_keyboard .active-buttons .button_9 {
  animation: keyboard_click .4s forwards;
  animation-delay: 5s; }

.animation .main_block .order_keyboard .active-buttons .button_10 {
  animation: keyboard_click .4s forwards;
  animation-delay: 3.4s; }

.animation .main_block .order_keyboard .active-buttons .button_11 {
  animation: keyboard_click .4s forwards;
  animation-delay: 4.8s; }

.animation .main_block .order_keyboard .active-buttons .button_12 {
  animation: keyboard_click .4s forwards;
  animation-delay: 4.6s; }

.animation .main_block .order_keyboard .active-buttons .button_13 {
  animation: keyboard_click .4s forwards;
  animation-delay: 4.4s; }

.animation .main_block .order_keyboard .active-buttons .button_14 {
  animation: keyboard_click .4s forwards;
  animation-delay: 4.2s; }

@-ms-keyframes scanner {
  0% {
    top: -100%;
    opacity: 0.5; }
  50% {
    top: 100%;
    opacity: 1; }
  100% {
    top: -100%;
    opacity: 0.5; } }

@-moz-keyframes scanner {
  0% {
    top: -100%;
    opacity: 0.5; }
  50% {
    top: 100%;
    opacity: 1; }
  100% {
    top: -100%;
    opacity: 0.5; } }

@-webkit-keyframes scanner {
  0% {
    top: -100%;
    opacity: 0.5; }
  50% {
    top: 100%;
    opacity: 1; }
  100% {
    top: -100%;
    opacity: 0.5; } }

@keyframes scanner {
  0% {
    top: -100%;
    opacity: 0.5; }
  50% {
    top: 100%;
    opacity: 1; }
  100% {
    top: -100%;
    opacity: 0.5; } }

@-ms-keyframes write_text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes write_text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes write_text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes write_text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes keyboard_click {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes keyboard_click {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes keyboard_click {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes keyboard_click {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(1800deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(1800deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1800deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(1800deg); } }
