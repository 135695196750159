@import "../../../components/App/variables.scss";

.project {
  &-similar {
    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 42px;
      &:last-child {
        margin-bottom: 48px;
      }
    }
    &__carts {
      display: flex;
      padding: 0 35px;
      margin-bottom: 65px;
    }
  }
}

@media (max-width: 991px) {
  .project {
    &-similar {
      &__carts {
        padding: 0 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  .project {
    &-similar {
      &__carts {
        flex-direction: column;
        padding: 0 16px;
      }
    }
  }
}

.margin {
  &:not(:last-child) {
    margin-right: 15px;
  }
}
